import { createContext, useState, useContext, useEffect, useCallback } from 'react';
import * as Types from '../types';
import axios from 'axios';

interface UserDataContextType {
    userData: Types.User | null;
    projects: Types.Project[] | null;
    isLoading: boolean;
    refreshUserData: () => Promise<void>;
    refreshProjectData: () => Promise<void>;
    logout: () => void;
}

const UserDataContext = createContext<UserDataContextType | null>(null);

export const UserDataProvider = ({ children }: any) => {
    const [token, setToken] = useState(localStorage.getItem('authToken'));
    const [userData, setUserData] = useState<Types.User | null>();
    const [projects, setProjects] = useState<Types.Project[] | null>();
    const [isLoading, setIsLoading] = useState(true);

    const fetchUserData = useCallback(async () => {
        if (!token) return;

        try {
          const response = await axios.get(`${import.meta.env.VITE_API_URL}/user`, {
              headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
              },
          });
          setUserData(response.data);
        } catch (error) {
            console.error('Error fetching user data:', error);
            localStorage.removeItem('authToken');
            setToken(null);
            setUserData(null);
        }
    }, [token]);

    const fetchProjectsData = useCallback(async () => {
        if (!token) return;

        try {
          const response = await axios.get(`${import.meta.env.VITE_API_URL}/user/projects`, {
              headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
              },
          });
          setProjects(response.data);
        } catch (error) {
            setProjects(null);
        }
    }, [token]);

    const fetchAllData = useCallback(async () => {
        setIsLoading(true);
        await Promise.all([fetchUserData(), fetchProjectsData()]);
        setIsLoading(false);
    }, [fetchUserData]);

    useEffect(() => {
        if (token) fetchAllData();
        if (!token) setIsLoading(false);
    }, [fetchAllData, token]);

    const logout = useCallback(() => {
        localStorage.removeItem('authToken');
        setToken(null);
        setUserData(null);
        window.location.replace("/");
    }, []);

    return (
        <UserDataContext.Provider
            value={{
              userData: userData || null,
              projects: projects || null,
              isLoading: isLoading,
              refreshUserData: fetchUserData,
              refreshProjectData: fetchProjectsData,
              logout,
            }}
        >
            {children}
        </UserDataContext.Provider>
    );
};

export default function useUserData() {
    const context = useContext(UserDataContext);
    if (!context) {
        throw new Error('useUserData must be used within a UserDataProvider');
    }
    return context;
}
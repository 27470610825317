import { useState, useEffect, useCallback } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from 'axios';
import css from './index.module.css';

import SEO from "@components/seo";
import MainContent from "@components/content";
import ContainerMini from "@components/containerMini";
import * as Types from '../../../types';
import useUserData from "@hooks/useUserData";
import { toast } from "sonner";

import Logo from '@assets/images/logoMini.svg';

export default function AdmTicketView() {
  let { id } = useParams();
  const { userData } = useUserData();
  const [ticket, setTicket] = useState<Types.Ticket | null>();
  const [updated, setUpdated] = useState<boolean>(true);

  useEffect(() => {
    if (updated === false) return;
    axios.get(`${import.meta.env.VITE_API_ADM_URL}/ticket/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('authToken')
      }})
    .then(response  => {
      setTicket(response.data);
      setUpdated(false);
    })
    .catch(_ => {
      setTicket(null);
    });
  }, [updated]);

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    const formData = new FormData(event.target);

    axios.post(`${import.meta.env.VITE_API_ADM_URL}/ticket/${ticket?.id}/send`, formData, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('authToken')
      }})
      .then(async (response) => {
        setUpdated(true);
        toast.success('Сообщение отправлено');
      });
  };

  const handleUpdateSubmit = async (event: any) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    formData.append('uuid', id ?? '');

    axios.post(`${import.meta.env.VITE_API_ADM_URL}/ticket/${ticket?.id}`, formData, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('authToken')
    }})
    .then(async (response) => {
      setUpdated(true);
      toast.success('Информация обновлена');
    });
  };

  function convertToDate(isoDate: any) {
    const date = new Date(isoDate);
    const options: any = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
    return new Intl.DateTimeFormat('ru-Ru', options).format(date);
  }

  return (
    <MainContent>    
      <SEO 
        title="Обращение"
        description="Магазин"
        ogUrl="https://artcons.io/"
      />

      <div className={css.headTicket}>
        <Link to={`/admin/tickets`} className={css.back}><i className="fi fi-rr-arrow-small-left"></i> Вернуться</Link>
        <h1>
          <div><a className={css.type}>{ticket?.type === 0 && "Вопрос"} {ticket?.type === 1 && "Проблема"} {ticket?.type === 2 && "Квоты"}</a> {ticket?.topic}</div>
          <a className={css.status}>{ticket?.status === 0 ? "На рассмотрении" : "Решили"}</a>
        </h1>
        <div>{ticket?.msg}</div>
      </div>

      <div className={css.flex}>
        <div className={css.col1}>
          <div className={css.msgs}>
            {ticket?.msgs.map(item => (
              <div className={css.msg}>
                {item.to === 'user' ?
                  <img alt="avatar" src={Logo}/>
                :
                  <img alt="avatar" src="https://avatars.mds.yandex.net/i?id=e3583305ecf833845bce0d64157b63b5_l-5220445-images-thumbs&n=13" />
                }

                <div className={css.text}>
                  <b>{item.to === 'user' ? "Поддержка" : "Пользователь"} <a className={css.date}>{convertToDate(item.createdAt)}</a></b>
                  <p>{item.msg}</p>
                </div>
              </div>
            ))}
          </div>
          
          <form className={css.form} onSubmit={handleSubmit}>
            <textarea name="msg" placeholder="Введите текст..."/>
            <button type="submit" style={{ width: "100%", background: "#0f51d4", marginTop: 10, padding: 10, color: "#fff" }}>Отправить сообщение</button>
          </form>
        </div>

        <div className={css.col2}>
          <form className={css.form} onSubmit={handleUpdateSubmit}>
            <div className={css.inputBlock} style={{ marginBottom: 10 }}>
              <label style={{fontSize: "14px",color: "var(--text-color)", marginBottom: 0 }}>Тип</label>
              <select name="type" defaultValue={ticket?.type}>
                  <option value={0} selected={ticket?.type === 0}>Вопрос</option>
                  <option value={1} selected={ticket?.type === 1}>Проблема</option>
                  <option value={2} selected={ticket?.type === 2}>Квоты</option>
              </select>
            </div>

            <div className={css.inputBlock} style={{ marginBottom: 10 }}>
              <label style={{fontSize: "14px",color: "var(--text-color)", marginBottom: 0 }}>Тема обращения</label>
              <input placeholder="Тема обращения" name="topic" defaultValue={ticket?.topic} />
            </div>

            <div className={css.inputBlock} style={{ marginBottom: 10 }}>
              <label style={{fontSize: "14px",color: "var(--text-color)", marginBottom: 0 }}>Первоначальное сообщение</label>
              <textarea placeholder="Первоначальное сообщение" name="msg" defaultValue={ticket?.msg} />
            </div>

            <div className={css.inputBlock} style={{ marginBottom: 10 }}>
              <label style={{fontSize: "14px",color: "var(--text-color)", marginBottom: 0 }}>Статус обращения</label>
              <select name="status" defaultValue={ticket?.status}>
                  <option value={0} selected={ticket?.status === 0}>На рассмотрении</option>
                  <option value={1} selected={ticket?.status === 1}>Решили</option>
              </select>
            </div>

            <div className={css.inputBlock} style={{ marginBottom: 10 }}>
              <label style={{fontSize: "14px",color: "var(--text-color)", marginBottom: 0 }}>Комментарий администратора</label>
              <textarea placeholder="Комментарий администратора" name="adminComment" defaultValue={ticket?.adminComment} />
            </div>
                
            <button type="submit" style={{ width: "100%", background: "#0f51d4", marginTop: 10, padding: 10, color: "#fff" }}>Обновить информацию</button>
          </form>
        </div>
      </div>
    </MainContent>
  )
}
import { useState, useEffect, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from 'axios';
import css from './index.module.css';

import SEO from "@components/seo";
import MainContent from "@components/content";
import useUserData from "@hooks/useUserData";
import ContainerMini from "@components/containerMini";

export default function AdmContainers () {
  const [containers, setContainers] = useState<any>([]);
  const navigate = useNavigate();
      
  useEffect(() => {
    axios.get(`${import.meta.env.VITE_API_ADM_URL}/containers`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      })
      .then(response  => {
        setContainers(response.data);
      })
      .catch(error => {
        setContainers([]);
      });
  }, []);

  return (
    <MainContent>    
      <SEO 
        title="Контейнеры"
        description=""
        ogUrl="https://artcons.io/"
      />

      <div className={css.head}>
        <h1>Контейнеры</h1>
      </div>

      {containers?.map((item: any) => (
        <ContainerMini 
          uuid={item.id.toString()}
          name={item.name}
          domain={item.domains.find((i: any) => i.type === 0)?.value!}
          status={item.status}
          isAdmin={true}
        />
      ))}
    </MainContent>
  )
}
import { FC } from "react";
import css from './index.module.css';
import { toast } from "sonner";

interface Tab {
  id: number | string,
  name: string,
  disabled?: boolean
}

interface Tabs {
  sections: Tab[],
  active?: number,
  setActive?: any,
  isFull?: boolean
}

const Tabs:FC<Tabs> = ({ sections, active, setActive, isFull }) => {
  const checkTab = (section: Tab) => {
    if (section.disabled) {
      toast.info('Скоро добавим');
      return;
    }

    setActive(section.id);
  }
  return (
    <div className={css.sections}>
        {sections.map(section => (
          <div className={`${css.section} ${active === section.id ? css.active : ''}`} style={isFull ? { width: "100%", textAlign: 'center' } : {}} onClick={() => checkTab(section)}>{section.name}</div>
        ))}
    </div>
  );
};

export default Tabs;
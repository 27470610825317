import { useState, useEffect, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from 'axios';
import css from './index.module.css';

import SEO from "@components/seo";
import MainContent from "@components/content";
import ContainerMini from "@components/containerMini";
import * as Types from '../../../types';
import useUserData from "@hooks/useUserData";
import * as Popups from './popups';
import { Type } from "./types";

export default function AdmExpenses() {
  const [expenses, setExpenses] = useState<any>([]);
  const [showCreate, setShowCreate] = useState<boolean>(false);
  const [showEdit, setShowEdit] = useState<boolean>(false);
  const [selected, setSelected] = useState<any>({});

  const { userData } = useUserData();
      
  useEffect(() => {
    axios.get(`${import.meta.env.VITE_API_ADM_URL}/pay/expenses`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      })
      .then(response  => {
        setExpenses(response.data);
      })
      .catch(error => {
        setExpenses([]);
      });
  }, [showCreate, showEdit]);

  const formatNumber = (num: any) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  };

  const editExpense = (data: any) => {
    setShowEdit(true);
    setSelected(data);
  }

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('ru-RU', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    });
  };

  return (
    <MainContent>    
        <Popups.Create showModal={showCreate} setShowModal={setShowCreate} />
        <Popups.Edit showModal={showEdit} setShowModal={setShowEdit} info={selected} />

        <SEO 
          title="Расходы"
          description=""
          ogUrl="https://artcons.io/"
        />

        <div className={css.head}>
            <h1>Расходы</h1>
            <i className="fi fi-rr-add" onClick={() => setShowCreate(true)}></i>
        </div>

        <div className={css.table}>
            <div className={css.tableHeader}>
                <a>Дата</a>
                <a>Название</a>
                <a>Сумма</a>
                <a>Создано</a>
            </div>

            <div className={css.tableBody}>
            {expenses.map((item: any) => (
              <div className={css.tableDiv} onClick={() => editExpense(item)}>
                <a>{formatDate(item.date)}</a>
                <a>{Type[item.type]}</a>
                <a>{formatNumber(item.value)} ₽</a>
                <a>{item.createdBy === '6a3b1894-8e1b-4065-943b-31cc60678368' ? 'Тимур А.' : 'Виктор К.'}</a>
              </div>
            ))}
            </div>
        </div>
    </MainContent>
  )
}
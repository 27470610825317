import { useState, useEffect, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from 'axios';
import css from './index.module.css';

import SEO from "@components/seo";
import MainContent from "@components/content";
import useUserData from "@hooks/useUserData";
import * as Blocks from './blocks';

import * as Types from '../../types';

export default function Wallet() {
    const { userData } = useUserData();
    const [recurrent, setRecurrent] = useState<Types.PayReccurrent[] | null>([]);
    const [history, setHistory] = useState<Types.PayHistory[] | null>([]);

    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        axios.get(`${import.meta.env.VITE_API_URL}/user/recurrent`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }
          })
          .then(response  => {
            setRecurrent(response.data);
          })
          .catch(_ => {
            setRecurrent(null);
        });

        axios.get(`${import.meta.env.VITE_API_URL}/user/history`, {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            }
            })
            .then(response  => {
              setHistory(response.data);
            })
            .catch(_ => {
              setRecurrent(null);
          });
    }, []);

    function convertToDate(isoDate: any) {
        const date = new Date(isoDate);
        const options: any = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
        return new Intl.DateTimeFormat('ru-Ru', options).format(date);
    }

    const formatNumber = (num: any) => {
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    };

    return (
    <MainContent width={600}>
        <SEO 
            title="Кошелек"
            description=""
            ogUrl="https://artcons.io/"
        />

        <Blocks.AddBalance showModal={showModal} setShowModal={setShowModal} />
        
        <div className={css.wallet}>
            <div className={css.col1}>
                <div className={css.walletBlock}>
                    <div className={css.textInfo}>
                        <h1 style={{ fontSize: 40 }}>{formatNumber(userData?.balance)} ₽</h1>
                        <a>ваш баланс</a>
                    </div>

                    {!userData?.payAcc.fio ?
                        <Link to={'/account'} className={css.addBalance} style={{ textDecoration: 'none', background: '#f54242' }}>Указать данные</Link>
                        :
                        <div className={css.addBalance} onClick={() => setShowModal(true)}>Пополнить баланс</div>
                    }
                </div>

                <div className={css.col2}>
                    <h2 style={{ margin: 0, fontSize: 20 }}>Платежи</h2>

                    <div className={css.recurrentList}>
                        {recurrent?.map(item => (
                            <Blocks.Recurrent
                                event={item.event}
                                value={formatNumber(item.value)}
                                updatedAt={convertToDate(item.updatedAt)}
                            />
                        ))}

                        {recurrent!.length <= 0 && <a style={{ marginTop: 10 }}>Не нашли платежей</a>}
                    </div>
                </div>

                <h2 style={{ margin: 0, fontSize: 20 }}>История</h2>

                <div className={css.history}>
                    {history?.map(item => (
                        <Blocks.History
                            type={item.type}
                            status={item.status}
                            event={item.event}
                            value={formatNumber(item.value)}
                            createdAt={convertToDate(item.createdAt)}
                        />    
                    ))}

                    {history!.length <= 0 && <a style={{ marginTop: 20 }}>Не нашли платежей</a>}
                </div>
            </div>
        </div>
    </MainContent>
    )
}
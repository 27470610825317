import { StrictMode, useEffect } from 'react'
import { createRoot } from 'react-dom/client'
import './index.css'
import App from './App.tsx'
import useUserData, { UserDataProvider } from "@hooks/useUserData.tsx";
import Logo from '@assets/images/logo.svg';

const LoadingPage = () => (
  <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh", width: "100%" }}>
    <div style={{ width: 200, display: "grid", justifyItems: "center" }}>
      <img src={Logo} width={100}/>
      <span className="loader"></span>

      <a style={{ position: "absolute", bottom: 50, display: "flex", justifyContent: "center", width: "100%", left: 0 }}>Прокачиваем ваш GTM</a>
    </div>
  </div>
);


const Load = () => {
  const { isLoading } = useUserData();
 
  return isLoading ? <LoadingPage /> : <App />;
};

createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <UserDataProvider>
      <Load />
    </UserDataProvider>
  </StrictMode>,
)

import { FC } from "react";
import { Link } from "react-router-dom";
import css from './index.module.css';
import Header from "../header";
import useUserData from "@hooks/useUserData";

interface MainContent {
  children: any;
  width?: number;
}

const MainContent:FC<MainContent> = ({ children, width }) => {
  const { userData } = useUserData();

  return (
    <div className={css.artCons}>
      {!userData?.payAcc.fio && <Link to={'/account'} className={css.payAcc}>Заполните платежные данные</Link> }
      <Header />

      <div className={css.artCons_body}>
        <div className={css.artCons_body_contentnCover} style={{ maxWidth: width }}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default MainContent;
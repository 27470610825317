import { useState, useEffect, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from 'axios';
import css from './index.module.css';

import SEO from "@components/seo";
import MainContent from "@components/content";
import useUserData from "@hooks/useUserData";
import * as Popups from './popups';
import { Faces, Status, Type } from "./types";

export default function AdmSettlements() {
  const [settlements, setSettlements] = useState<any>([]);
  const [showCreate, setShowCreate] = useState<boolean>(false);
  const [showEdit, setShowEdit] = useState<boolean>(false);
  const [selected, setSelected] = useState<any>({});

  const { userData } = useUserData();
      
  useEffect(() => {
    axios.get(`${import.meta.env.VITE_API_ADM_URL}/pay/settlements`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      })
      .then(response  => {
        setSettlements(response.data);
      })
      .catch(error => {
        setSettlements([]);
      });
  }, [showCreate, showEdit]);

  const formatNumber = (num: any) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  };

  const editExpense = (data: any) => {
    setShowEdit(true);
    setSelected(data);
  }

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('ru-RU', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    });
  };

  return (
    <MainContent>    
      <Popups.Create showModal={showCreate} setShowModal={setShowCreate} />
      <Popups.Edit showModal={showEdit} setShowModal={setShowEdit} info={selected} />

      <SEO 
        title="Взаиморасчеты"
        description=""
        ogUrl="https://artcons.io/"
      />

      <div className={css.head}>
        <h1>Взаиморасчеты</h1>
        <i className="fi fi-rr-add" onClick={() => setShowCreate(true)}></i>
      </div>

      <div className={css.table}>
        <div className={css.tableHeader}>
          <a>Дата</a>
          <a>От кого</a>
          <a>Кому</a>
          <a>Назначение</a>
          <a>Сумма</a>
          <a>Статус</a>
        </div>

        <div className={css.tableBody}>
          {settlements.map((item: any) => (
            <div className={css.tableDiv} onClick={() => editExpense(item)}>
              <a>{formatDate(item.createdAt)}</a>
              <a>{Faces[item.from]}</a>
              <a>{Faces[item.to]}</a>
              <a>{item.event}</a>
              <a>{formatNumber(item.value)} ₽</a>
              <a>{Status[item.status]}</a>
            </div>
          ))}
        </div>
      </div>
    </MainContent>
  )
}
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { AreaChart, Area, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, XAxis } from 'recharts';

interface Chart {
  data: any;
}

interface CustomTooltip {
  active?: any;
  payload?: any;
  label?: any;
}

export const Payments: FC<Chart> = ({ data }) => {
    const { t } = useTranslation();
    
    const chartData = Object.entries(data.daily || {}).map(([date, values]: [string, any]) => ({
      date,
      count: values.count,
      total: parseFloat(values.total)
    }));

    return (
      <ResponsiveContainer width="100%" height={250}>
        <AreaChart data={chartData} style={{ right: 20 }}>
            <CartesianGrid strokeDasharray="1" />
            <YAxis dataKey="total" />
            <XAxis dataKey="date" />
            <Area 
              type="monotone" 
              dataKey="total" 
              stroke="#8884d8" 
              fill="#8884d8" 
            />
            <Tooltip content={<CustomTooltip />} />
        </AreaChart>
      </ResponsiveContainer>
    );
}

export const PaymentsCount: FC<Chart> = ({ data }) => {
  const { t } = useTranslation();
  
  const chartData = Object.entries(data.daily || {}).map(([date, values]: [string, any]) => ({
    date,
    count: values.count
  }));

  return (
    <ResponsiveContainer width="100%" height={250}>
      <AreaChart data={chartData} style={{ right: 20 }}>
          <CartesianGrid strokeDasharray="1" />
          <YAxis dataKey="count" />
          <XAxis dataKey="date" />
          <Area 
            type="monotone" 
            dataKey="count" 
            stroke="#8884d8" 
            fill="#8884d8" 
          />
          <Tooltip content={<CustomTooltip />} />
      </AreaChart>
    </ResponsiveContainer>
  );
}

export const CustomTooltip:FC<CustomTooltip> = ({ active, payload }) => { 
  const formatNumber = (num: any) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  };

  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <a className="label">{`${payload[0].payload.date}`}</a><br />
        <b className="label" style={{ color: "var(--text-color)", fontWeight: 600 }}>{payload[0].payload.total ? formatNumber(payload[0].payload.total) + ' ₽': payload[0].payload.count + ' шт.'}</b>
      </div>
    );
  }
  
  return null;
};
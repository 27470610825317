import { useState, useEffect, FC } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from 'axios';
import css from './index.module.css';

import { useTranslation } from 'react-i18next';
import { toast } from 'sonner';
import Popup from "@components/popup";

interface Modal {
    showModal: boolean; 
    setShowModal: any;
    info?: any;
}

export const Create:FC<Modal> = ({ showModal, setShowModal }) => {
    const { t } = useTranslation();

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        const formData = new FormData(event.target);

        axios.post(`${import.meta.env.VITE_API_ADM_URL}/pay/settlements`, formData, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            }
        }).then(_ => {       
            event.target.reset();
            toast.success("Взаиморасчет добавлен");
            setShowModal(false);
        }).catch(_ => {
            toast.error(t('gtm.err'));
        });
    };
  
    return (
        <Popup showPopup={showModal} setShowPopup={setShowModal}>
            <h5 style={{ fontSize: '17px', marginBottom: 10, marginTop: 0 }}>Добавить взаиморасчет</h5>

            <form onSubmit={handleSubmit}>
                <div className={css.inputBlock} style={{ marginBottom: 10 }}>
                    <label style={{fontSize: "14px",color: "var(--text-color)", marginBottom: 0 }}>От кого</label>
                    <select name="from">
                        <option value={0}>ИП Артиков Тимур Дилшодович</option>
                        <option value={1}>ООО «Вариант»</option>
                    </select>
                </div>

                <div className={css.inputBlock} style={{ marginBottom: 10 }}>
                    <label style={{fontSize: "14px",color: "var(--text-color)", marginBottom: 0 }}>Кому</label>
                    <select name="to">
                        <option value={0}>ИП Артиков Тимур Дилшодович</option>
                        <option value={1}>ООО «Вариант»</option>
                    </select>
                </div>

                <div className={css.inputBlock} style={{ marginBottom: 10 }}>
                    <label style={{fontSize: "14px",color: "var(--text-color)", marginBottom: 0 }}>Назначение</label>
                    <input placeholder="Назначение" name="event" />
                </div>

                <div className={css.inputBlock} style={{ marginBottom: 10 }}>
                    <label style={{fontSize: "14px",color: "var(--text-color)", marginBottom: 0 }}>Сумма</label>
                    <input placeholder="Сумма" type="number" name="value" />
                </div>

                <div className={css.inputBlock} style={{ marginBottom: 10 }}>
                    <label style={{fontSize: "14px",color: "var(--text-color)", marginBottom: 0 }}>Статус</label>
                    <select name="status">
                        <option value={0}>Не оплачен</option>
                        <option value={1}>Оплачен</option>
                        <option value={2}>Акт отправлен</option>
                        <option value={3}>Акт подписан</option>
                    </select>
                </div>

                <button style={{ background: "#6258ee", width: '100%' }} className="btn" type="submit">Добавить взаиморасчет</button>
            </form>
        </Popup>
    );
}

export const Edit:FC<Modal> = ({ showModal, setShowModal, info }) => {
    const { t } = useTranslation();

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        formData.append('id', info.id);
        
        axios.post(`${import.meta.env.VITE_API_ADM_URL}/pay/settlement`, formData, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            }
        }).then(_ => {       
            event.target.reset();
            toast.success("Взаиморасчет обновлен");
            setShowModal(false);
        }).catch(_ => {
            toast.error(t('gtm.err'));
        });
    };
  
    return (
        <Popup showPopup={showModal} setShowPopup={setShowModal}>
            <h5 style={{ fontSize: '17px', marginBottom: 10, marginTop: 0 }}>Обновить взаиморасчет</h5>

            <form onSubmit={handleSubmit}>
                <div className={css.inputBlock} style={{ marginBottom: 10 }}>
                    <label style={{fontSize: "14px",color: "var(--text-color)", marginBottom: 0 }}>От кого</label>
                    <select name="from" defaultValue={info.from}>
                        <option value={0} selected={info.from === 0}>ИП Артиков Тимур Дилшодович</option>
                        <option value={1} selected={info.type === 1}>ООО «Вариант»</option>
                    </select>
                </div>

                <div className={css.inputBlock} style={{ marginBottom: 10 }}>
                    <label style={{fontSize: "14px",color: "var(--text-color)", marginBottom: 0 }}>Кому</label>
                    <select name="to" defaultValue={info.to}>
                        <option value={0} selected={info.to === 0}>ИП Артиков Тимур Дилшодович</option>
                        <option value={1} selected={info.to === 1}>ООО «Вариант»</option>
                    </select>
                </div>

                <div className={css.inputBlock} style={{ marginBottom: 10 }}>
                    <label style={{fontSize: "14px",color: "var(--text-color)", marginBottom: 0 }}>Назначение</label>
                    <input placeholder="Назначение" name="event" defaultValue={info.event}/>
                </div>

                <div className={css.inputBlock} style={{ marginBottom: 10 }}>
                    <label style={{fontSize: "14px",color: "var(--text-color)", marginBottom: 0 }}>Сумма</label>
                    <input placeholder="Сумма" type="number" name="value" defaultValue={info.value} />
                </div>

                <div className={css.inputBlock} style={{ marginBottom: 10 }}>
                    <label style={{fontSize: "14px",color: "var(--text-color)", marginBottom: 0 }}>Статус</label>
                    <select name="status" defaultValue={info.status}>
                        <option value={0} selected={info.status === 0}>Не оплачен</option>
                        <option value={1} selected={info.status === 1}>Оплачен</option>
                        <option value={2} selected={info.status === 2}>Акт отправлен</option>
                        <option value={3} selected={info.status === 3}>Акт подписан</option>
                        <option value={4} selected={info.status === 4}>Отменен</option>
                    </select>
                </div>

                <button style={{ background: "#6258ee", width: '100%' }} className="btn" type="submit">Обновить взаиморасчет</button>
            </form>
        </Popup>
    );
}
import { Link } from "react-router-dom";
import css from './index.module.css';
import SEO from "@components/seo";

export default function Mobile () {
  return (
    <div className={css.container}>
        <SEO 
            title="Страница не найдена"
            description="Artcons"
            ogUrl="https://artcons.io/"
        />

        <div className={css.component}>        
            <div>
                <h1>Войдите с ПК</h1>
                <a>Мы еще не адаптировали портал под мобильные устройства, пожалуйста, войдите с компьютера.</a>
            </div>
        </div>

    </div>
  )
}

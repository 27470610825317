import css from './index.module.css';

export const Faces: { [key: number]: string } = {
    0: 'ИП Артиков Т.Д.',
    1: 'ООО «Вариант»'
}

export const Type: { [key: number]: string } = {
    0: 'Уплата налога',
    1: 'Пени, штрафы',
    2: 'Комиссия за эквайринг',
    3: 'Обслуживание серверов GTM',
    4: 'Обслуживание платформы',
    5: 'Иное'
}

export const Status: { [key: number]: JSX.Element } = {
    0: <div className={`${css.status} ${css.notPay}`}>Не оплачен</div>,
    1: <div className={`${css.status} ${css.pay}`}>Оплачен</div>,
    2: <div className={`${css.status} ${css.act}`}>Акт отправлен</div>,
    3: <div className={`${css.status} ${css.success}`}>Выполнен</div>,
    4: <div className={`${css.status} ${css.cancel}`}>Отменен</div>
}
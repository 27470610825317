import { useState, useEffect, FC } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from 'axios';
import css from './index.module.css';

import { useTranslation } from 'react-i18next';
import { toast } from 'sonner';
import Popup from "@components/popup";

interface Modal {
    showModal: boolean; 
    setShowModal: any;
    id?: any;
}

export const CreatePayment:FC<Modal> = ({ showModal, setShowModal, id }) => {
    const { t } = useTranslation();

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        formData.append('userId', id);

        axios.post(`${import.meta.env.VITE_API_ADM_URL}/user/${id}/balance`, formData, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            }
        }).then(_ => {       
            event.target.reset();
            toast.success("Платеж добавлен");
            setShowModal(false);
        }).catch(_ => {
            toast.error(t('gtm.err'));
        });
    };
  
    return (
        <Popup showPopup={showModal} setShowPopup={setShowModal}>
            <h5 style={{ fontSize: '17px', marginBottom: 10, marginTop: 0 }}>Добавить платеж</h5>

            <form onSubmit={handleSubmit}>
                <div className={css.inputBlock} style={{ marginBottom: 10 }}>
                    <label style={{fontSize: "14px",color: "var(--text-color)", marginBottom: 0 }}>Тип</label>
                    <select name="type">
                        <option value={0}>Списание</option>
                        <option value={1}>Пополнение</option>
                    </select>
                </div>

                <div className={css.inputBlock} style={{ marginBottom: 10 }}>
                    <label style={{fontSize: "14px",color: "var(--text-color)", marginBottom: 0 }}>Название</label>
                    <input placeholder="Название" name="event" />
                </div>

                <div className={css.inputBlock} style={{ marginBottom: 10 }}>
                    <label style={{fontSize: "14px",color: "var(--text-color)", marginBottom: 0 }}>Сумма</label>
                    <input placeholder="Сумма" type="number" name="value" />
                </div>

                <button style={{ background: "#6258ee", width: '100%' }} className="btn" type="submit">Добавить платеж</button>
            </form>
        </Popup>
    );
}
import { useState, useEffect, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from 'axios';
import css from './index.module.css';

import SEO from "@components/seo";
import MainContent from "@components/content";
import SupportMini from "@components/supportMini";

import * as Types from '../../../types';

export default function AdmTickets() {
  const [tickets, setTickets] = useState<Types.Ticket[]>([]);
        
  useEffect(() => {
    axios.get(`${import.meta.env.VITE_API_ADM_URL}/tickets`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('authToken')
      }
      })
      .then(response  => {
        setTickets(response.data);
      })
      .catch(error => {
        setTickets([]);
    });
  }, []);

  function convertToDate(isoDate: any) {
    const date = new Date(isoDate);
    const options: any = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
    return new Intl.DateTimeFormat('ru-Ru', options).format(date);
  }
  
  return (
    <MainContent>    
      <SEO 
        title="Обращения"
        description=""
        ogUrl="https://artcons.io/"
      />

      <div className={css.head}>
        <h1>Обращения</h1>
      </div>

      <div className={css.containers}>
        {tickets.map(item => (
          <SupportMini 
            uuid={item.id.toString()}
            name={item.topic}
            domain={convertToDate(item.createdAt)}
            status={item.status}
            isAdmin={true}
          />
        ))}

        {tickets.length <= 0 && <a>Обращений нет.</a>}
      </div>
    </MainContent>
  )
}
import { useState, useEffect, useCallback } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from 'axios';
import css from './index.module.css';

import SEO from "@components/seo";
import MainContent from "@components/content";
import useUserData from "@hooks/useUserData";
import ContainerMini from "@components/containerMini";

export default function AdmContainer () {
  let { id } = useParams();
  const [container, setContainer] = useState<any>({});
  const navigate = useNavigate();
      
  useEffect(() => {
    axios.get(`${import.meta.env.VITE_API_ADM_URL}/container/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('authToken')
      }
    })
    .then(response  => {
      setContainer(response.data);
    })
    .catch(error => {
      setContainer({});
    });
  }, []);

  return (
    <MainContent>    
      <SEO 
        title="Контейнер"
        description=""
        ogUrl="https://artcons.io/"
      />

      <div className={css.head}>
        <h1>Контейнер</h1>
      </div>

      <form>
        <h2>Основные данные</h2>

        <div className={css.data}>
          <a>ID</a>
          <b>{container?.id}</b>
        </div>

        <div className={css.data}>
          <a>userID</a>
          <b>{container?.userId}</b>

          <Link to={`/admin/user/${container?.userId}`}>Перейти в карточку</Link>
        </div>

        <div className={css.data}>
          <a>serverId</a>
          <b>{container?.serverId}</b>

          <Link to={`https://timeweb.cloud/my/servers/${container.serverId}`}>Перейти в Timeweb.Cloud</Link>
        </div>

        <div className={css.data}>
          <a>Название</a>
          <b>{container?.name}</b>
        </div>

        <div className={css.data}>
          <a>GTM Token</a>
          <b>{container?.gtmToken}</b>
        </div>

        <div className={css.data}>
          <a>Тариф</a>
          {container?.tariff === 0 && <b>Базовый — 2 500 ₽/мес</b>}
          {container?.tariff === 1 && <b>Стандартный — 5 000 ₽/мес</b>}
          {container?.tariff === 2 && <b>Бизнес — 10 000 ₽/мес</b>}
        </div>

        <div className={css.data}>
          <a>Локация</a>
          {container?.location === 'ru-0' && <b>Россия (Санкт-Петербург)</b>}
          {container?.tariff === 'ru-1' && <b>Россия (Москва)</b>}
          {container?.tariff === 'nl-0' && <b>Нидерланды (Амстердам)</b>}
        </div>

        <div className={css.data}>
          <a>Статус</a>
          <b>{container?.status === 5 ? 'Активен' : 'Настраивается'}</b>
        </div>

        <div className={css.data}>
          <a>Комментарий</a>
          <b>{container?.adminComment || 'Нет комментария'}</b>
        </div>
      </form>
    </MainContent>
  )
}
import { useState, useEffect, useCallback } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from 'axios';
import css from './index.module.css';

import SEO from "@components/seo";
import MainContent from "@components/content";
import useUserData from "@hooks/useUserData";
import { toast } from "sonner";
import Tabs from "@components/tabs";
import * as Blocks from '../../wallet/blocks';
import { CreatePayment } from "./popups";

export default function AdmUser() {
  let { id } = useParams();
  const [user, setUsers] = useState<any>({});
  const [history, setHistory] = useState<any>([]);
  const [recurrent, setRecurrent] = useState<any>([]);

  const [showCreatePayment, setShowCreatePayment] = useState<boolean>(false);
  const [tab, setTab] = useState(0);
  const [selected, setSelected] = useState<any>({});
  const [payType, setType] = useState(0);

  useEffect(() => {
    axios.get(`${import.meta.env.VITE_API_ADM_URL}/user/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('authToken')
      }
    })
    .then(response  => {
      setUsers(response.data);
    })
    .catch(error => {
      setUsers({});
    });

    axios.get(`${import.meta.env.VITE_API_ADM_URL}/user/${id}/recurrent`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('authToken')
      }
    })
    .then(response  => {
      setRecurrent(response.data);
    })
    .catch(error => {
      setRecurrent([]);
    });

    axios.get(`${import.meta.env.VITE_API_ADM_URL}/user/${id}/history`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('authToken')
      }
    })
    .then(response  => {
      setHistory(response.data);
    })
    .catch(error => {
      setHistory([]);
    });
  }, []);

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    formData.append('uuid', id ?? '');
    
    axios.post(`${import.meta.env.VITE_API_ADM_URL}/user/${id}`, formData, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('authToken')
      }
    }).then(_ => {       
      event.target.reset();
      toast.success("Информация обновлена");
    }).catch(_ => {
      toast.error('Ошибка');
    });
  };

  function convertToDate(isoDate: any) {
    const date = new Date(isoDate);
    const options: any = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
    return new Intl.DateTimeFormat('ru-Ru', options).format(date);
  }

  const formatNumber = (num: any) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  };

  return (
    <MainContent>    
      <CreatePayment showModal={showCreatePayment} setShowModal={setShowCreatePayment} id={id} />

      <SEO 
        title="Пользователь"
        description=""
        ogUrl="https://artcons.io/"
      />

      <div className={css.head}>
        <h1>Пользователь</h1>
      </div>

      <div className={css.tabs}>
        <Tabs 
          sections={[
            {
              id: 0,
              name: 'Общая информация'
            },
            {
              id: 1,
              name: 'Платежный аккаунт'
            }
          ]}

          active={tab}
          setActive={setTab}
        />
      </div>
        
      {tab === 0 &&
        <form className={css.form} onSubmit={handleSubmit}>
          <h2>Основные данные</h2>

          <div className={css.input}>
            <label>Имя</label>
            <input name="firstName" placeholder="Имя" defaultValue={user?.firstName}/>
          </div>

          <div className={css.input}>
            <label>Фамилия</label>
            <input name="lastName" placeholder="Фамилия" defaultValue={user?.lastName}/>
          </div>

          <div className={css.input}>
            <label>Номер телефона</label>
            <input name="phone" placeholder="Номер телефона" defaultValue={user?.phone} disabled/>
          </div>

          <div className={css.input}>
            <label>Электронная почта</label>
            <input name="email" placeholder="Электронная почта" defaultValue={user?.email}/>
          </div>

          <div className={css.input}>
            <label>Промокод</label>
            <input name="promocode" placeholder="Промокод" defaultValue={user?.promocode}/>
          </div>

          <h2>Платежные данные</h2>

          <div className={css.input}>
              <label>Тип аккаунта</label>
              <select name="payAcc[type]" value={payType} onChange={(e) => setType(parseInt(e.target.value))}>
                <option value={0}>Физическое лицо</option>
                <option value={1}>Юридическое лицо</option>
              </select>
          </div>

          {payType === 0 && 
          <>
            <div className={css.input}>
                <label>Фамилия Имя Отчество</label>
                <input name="payAcc[fio]" defaultValue={user?.payAcc?.fio} placeholder="Фамилия Имя Отчество"/>
            </div>

            <div className={css.input}>
                <label>Паспорт: серия</label>
                <input name="payAcc[passportSerial]" defaultValue={user?.payAcc?.passportSerial} placeholder="Серия паспорта"/>
            </div>

            <div className={css.input}>
                <label>Паспорт: номер</label>
                <input name="payAcc[passportNumber]" defaultValue={user?.payAcc?.passportNumber} placeholder="Номер паспорта"/>
            </div>
            
            <div className={css.input}>
                <label>Паспорт: Кем выдан?</label>
                <input name="payAcc[passportAssigned]" defaultValue={user?.payAcc?.passportAssigned} placeholder="Кем выдан паспорт"/>
            </div>

            <div className={css.input}>
                <label>Дата выдачи</label>
                <input name="payAcc[passportDate]" defaultValue={user?.payAcc?.passportDate} placeholder="Дата выдачи паспорта"/>
            </div>

            <div className={css.input}>
                <label>Адрес регистрации</label>
                <input name="payAcc[passportReg]" defaultValue={user?.payAcc?.passportReg} placeholder="Адрес регистрации"/>
            </div>
          </>
          }

          {payType === 1 && 
          <>
            <div className={css.input}>
                <label>Наименование организации</label>
                <input name="payAcc[org]" placeholder="Наименование организации" defaultValue={user?.payAcc?.org}/>
            </div>

            <div className={css.input}>
                <label>ИНН</label>
                <input name="payAcc[inn]" placeholder="ИНН" defaultValue={user?.payAcc?.inn}/>
            </div>
            
            <div className={css.input}>
                <label>Название банка</label>
                <input name="payAcc[bank][bankName]" placeholder="Название банка" defaultValue={user?.payAcc?.bank?.bankName}/>
            </div>

            <div className={css.input}>
                <label>Расчетный счет</label>
                <input name="payAcc[bank][payAcc]" placeholder="Расчетный счет" defaultValue={user?.payAcc?.bank?.payAcc}/>
            </div>

            <div className={css.input}>
                <label>Корреспонденский счет</label>
                <input name="payAcc[bank][corAcc]" placeholder="Корреспонденский счет" defaultValue={user?.payAcc.bank?.corAcc}/>
            </div>

            <div className={css.input}>
                <label>БИК Банка</label>
                <input name="payAcc[bank][bik]" placeholder="БИК Банка" defaultValue={user?.payAcc?.bank?.bik}/>
            </div>

            <div className={css.input}>
                <label>Контактное лицо</label>
                <input name="payAcc[fio]" placeholder="Контактное лицо" defaultValue={user?.payAcc?.fio}/>
            </div>
          </>
        }

        <button style={{ width: "100%", marginTop: 20 }}>Сохранить</button>
        </form>
      }

      {tab === 1 && 
        <>
          <div className={css.balance}>
            <a>Баланс счета</a>
            <b>{user?.balance} ₽</b>
          </div>

          <div className={css.col2}>
            <h2 style={{ margin: 0, fontSize: 20, marginBottom: 10 }}>Платежи</h2>

            <div className={css.recurrentList}>
              {recurrent?.map((item: any) => (
                <Blocks.Recurrent
                  event={item.event}
                  value={formatNumber(item.value)}
                  updatedAt={convertToDate(item.updatedAt)}
                />
              ))}

              {recurrent!.length <= 0 && <a style={{ marginTop: 10 }}>Не нашли платежей</a>}
            </div>
          </div>

          <h2 style={{ margin: 0, marginBottom: 10, fontSize: 20, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>История <i onClick={() => setShowCreatePayment(true)} className="fi fi-rr-add"></i></h2>

          <div className={css.history}>
            {history?.map((item: any) => (
              <Blocks.History
                  type={item.type}
                  status={item.status}
                  event={item.event}
                  value={formatNumber(item.value)}
                  createdAt={convertToDate(item.createdAt)}
              />    
            ))}

            {history!.length <= 0 && <a style={{ marginTop: 20 }}>Не нашли платежей</a>}
          </div>
        </>
      }

    </MainContent>
  )
}
import { useState, useEffect, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from 'axios';
import css from './index.module.css';

import SEO from "@components/seo";
import MainContent from "@components/content";
import useUserData from "@hooks/useUserData";

import VK from '@assets/images/vk.svg';
import YA from '@assets/images/ya.svg';
import LeeCyber from '@assets/images/leecyber.svg';
import { toast } from "sonner";

export default function Account() {
    const { userData, refreshUserData, logout } = useUserData();
    const [payType, setType] = useState(0);

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        const formData = new FormData(event.target);
    
        axios.post(`${import.meta.env.VITE_API_URL}/user`, formData, {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }})
          .then(async (response) => {
            toast.success('Информация обновлена');
            refreshUserData();
          });
    };
    
    return (
    <MainContent width={750}>
        <SEO 
            title="Аккаунт"
            description=""
            ogUrl="https://artcons.io/"
        />

        <h1 style={{ margin: 0 }}>{userData?.firstName} {userData?.lastName}</h1>
        
        <div className={css.flex}>
            <form className={css.form} onSubmit={handleSubmit}>
                <h2>Основные данные</h2>

                <div className={css.input}>
                    <label>Имя</label>
                    <input name="firstName" placeholder="Имя" defaultValue={userData?.firstName}/>
                </div>

                <div className={css.input}>
                    <label>Фамилия</label>
                    <input name="lastName" placeholder="Фамилия" defaultValue={userData?.lastName}/>
                </div>

                <h2>Платежные данные</h2>

                <div className={css.input}>
                    <label>Тип аккаунта</label>
                    <select name="payAcc[type]" value={payType} onChange={(e) => setType(parseInt(e.target.value))}>
                        <option value={0}>Физическое лицо</option>
                        <option value={1}>Юридическое лицо</option>
                    </select>
                </div>

                {payType === 0 && 
                <>
                    <div className={css.input}>
                        <label>Фамилия Имя Отчество</label>
                        <input name="payAcc[fio]" defaultValue={userData?.payAcc.fio} placeholder="Фамилия Имя Отчество"/>
                    </div>

                    <div className={css.input}>
                        <label>Паспорт: серия</label>
                        <input name="payAcc[passportSerial]" defaultValue={userData?.payAcc.passportSerial} placeholder="Серия паспорта"/>
                    </div>

                    <div className={css.input}>
                        <label>Паспорт: номер</label>
                        <input name="payAcc[passportNumber]" defaultValue={userData?.payAcc.passportNumber} placeholder="Номер паспорта"/>
                    </div>
                    
                    <div className={css.input}>
                        <label>Паспорт: Кем выдан?</label>
                        <input name="payAcc[passportAssigned]" defaultValue={userData?.payAcc.passportAssigned} placeholder="Кем выдан паспорт"/>
                    </div>

                    <div className={css.input}>
                        <label>Дата выдачи</label>
                        <input name="payAcc[passportDate]" defaultValue={userData?.payAcc.passportDate} placeholder="Дата выдачи паспорта"/>
                    </div>

                    <div className={css.input}>
                        <label>Адрес регистрации</label>
                        <input name="payAcc[passportReg]" defaultValue={userData?.payAcc.passportReg} placeholder="Адрес регистрации"/>
                    </div>
                </>
                }

                {payType === 1 && 
                <>
                    <div className={css.input}>
                        <label>Наименование организации</label>
                        <input name="payAcc[org]" placeholder="Наименование организации" defaultValue={userData?.payAcc.org}/>
                    </div>

                    <div className={css.input}>
                        <label>ИНН</label>
                        <input name="payAcc[inn]" placeholder="ИНН" defaultValue={userData?.payAcc.inn}/>
                    </div>
                    
                    <div className={css.input}>
                        <label>Название банка</label>
                        <input name="payAcc[bank][bankName]" placeholder="Название банка" defaultValue={userData?.payAcc.bank?.bankName}/>
                    </div>

                    <div className={css.input}>
                        <label>Расчетный счет</label>
                        <input name="payAcc[bank][payAcc]" placeholder="Расчетный счет" defaultValue={userData?.payAcc.bank?.payAcc}/>
                    </div>

                    <div className={css.input}>
                        <label>Корреспонденский счет</label>
                        <input name="payAcc[bank][corAcc]" placeholder="Корреспонденский счет" defaultValue={userData?.payAcc.bank?.corAcc}/>
                    </div>

                    <div className={css.input}>
                        <label>БИК Банка</label>
                        <input name="payAcc[bank][bik]" placeholder="БИК Банка" defaultValue={userData?.payAcc.bank?.bik}/>
                    </div>

                    <div className={css.input}>
                        <label>Контактное лицо</label>
                        <input name="payAcc[fio]" placeholder="Контактное лицо" defaultValue={userData?.payAcc.fio}/>
                    </div>
                </>
                }

                <button type="submit" style={{ width: "100%", marginTop: 20 }}>Сохранить</button>
                <div className={css.logout} onClick={logout}>Выйти из аккаунта</div>
            </form>

            <div className={css.safety}>
                <div className={css.btnSafe} onClick={() => toast.info('Временно недоступно, обратитесь в поддержку')}>
                    <div className={css.icon}>
                        <i className="fi fi-rr-phone-call"></i>
                    </div>
                    <a>Обновить телефон</a>
                </div>

                <div className={css.btnSafe} onClick={() => toast.info('Временно недоступно, обратитесь в поддержку')}>
                    <div className={css.icon}>
                        <i className="fi fi-rr-at"></i>
                    </div>
                    <a>Обновить почту</a>
                </div>
                
                {userData?.leecyber &&
                    <Link to={'https://dash.leecyber.com/id/connected'} className={css.btnSafe}>
                        <div className={css.icon} style={{ background: "#fff", color: "#000", border: '1px solid #00000041' }}>
                            <img src={LeeCyber} style={{ width: 15 }} alt="leecyber" />
                        </div>
                        <a>LeeCyber.ID</a>
                    </Link>
                }

            {/* 
                <div className={css.btnSafe}>
                    <div className={css.icon} style={{ background: "#fff", color: "#000", border: '1px solid #00000041' }}>
                        <i className="fi fi-brands-telegram"></i>
                    </div>
                    <a>Telegram</a>
                </div>

                <div className={css.btnSafe}>
                    <div className={css.icon} style={{ background: "#fff", color: "#000", border: '1px solid #00000041' }}>
                        <img src={VK} style={{ width: 15 }} alt="vk" />
                    </div>
                    <a>VK ID</a>
                </div>

                <div className={css.btnSafe}>
                    <div className={css.icon} style={{ background: "#fff", color: "#000", border: '1px solid #00000041' }}>
                        <img src={YA} style={{ width: 15 }} alt="yandex" />
                    </div>
                    <a>Yandex ID</a>
                </div>
                
                <div className={css.btnSafe}>
                    <div className={css.icon} style={{ background: "#fff", color: "#000", border: '1px solid #00000041' }}>
                        <img src={LeeCyber} style={{ width: 15 }} alt="leecyber" />
                    </div>
                    <a>LeeCyber ID</a>
                </div> */}
            </div>
        </div>
    </MainContent>
    )
}
import { useState, useEffect, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from 'axios';
import css from './index.module.css';

import SEO from "@components/seo";
import MainContent from "@components/content";
import ContainerMini from "@components/containerMini";
import * as Types from '../../../types';
import useUserData from "@hooks/useUserData";

export default function AdmTransactions() {
  const [period, setPeriod] = useState<any>('current');
  const [transactions, setTransactions] = useState<any>({});
  const [selected, setSelected] = useState<any>({});
  const navigate = useNavigate();

  const { userData } = useUserData();
      
  useEffect(() => {
    axios.get(`${import.meta.env.VITE_API_ADM_URL}/pay/transactions?period=${period}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      })
      .then(response  => {
        setTransactions(response.data);
      })
      .catch(error => {
        setTransactions({});
      });
  }, [period]);

  const formatNumber = (num: any) => {
    if (num == null) return '0';
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('ru-RU', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    });
  };

  const calculateNetIncome = (number: number, tariff: number) => {
    let netIncome = number;
    netIncome -= (netIncome * 4) / 100;
    netIncome -= (netIncome * 8) / 100;

    switch (tariff) {
      case 0:
        netIncome -= 850;
        break;
      case 1:
        netIncome -= 1650;
        break;
      case 2:
        netIncome -= 4200;
        break;
      default:
        console.error('Недопустимый тариф');
    }
    return netIncome;
  };

  return (
    <MainContent>    
        <SEO 
          title="Расходы"
          description=""
          ogUrl="https://artcons.io/"
        />

        <div className={css.head}>
          <h1>Расходы</h1>
          <select onChange={(e) => setPeriod(e.target.value)} value={period}>
            <option value="current">Текущий период</option>
            <option value="previous">Прошедший период</option>
          </select>
        </div>

        <h3>Акт сверки</h3>

        <div className={css.act}>
          <div className={css.row} style={{ borderTop: '1px solid rgba(0, 0, 0, 0.301)'}}>
            <b>Дата начала периода</b>
            <a>{formatDate(transactions?.startDate)}, 00:00</a>
          </div>

          <div className={css.row}>
            <b>Дата конца периода</b>
            <a>{formatDate(transactions?.endDate)}, 23:59</a>
          </div>

          <div className={css.row}>
            <b>Всего получено</b>
            <a>{formatNumber(transactions?.totalWithoutTax)} ₽</a>
          </div>

          <div className={css.row}>
            <b>Всего получено с вычетом комиссии эквайринга</b>
            <a>{formatNumber(transactions?.totalAfterCommission)} ₽</a>
          </div>

          <div className={css.row}>
            <b>Всего получено с вычетом налога (8%)</b>
            <a>{formatNumber(transactions?.totalWithTax)} ₽</a>
          </div>

          <div className={css.row}>
            <b>Всего получено с вычетом расходов (серверы)</b>
            <a>{formatNumber(transactions?.totalAfterTariff)} ₽</a>
          </div>

          <div className={css.row} style={{ background: "#e3e3e3" }}>
            <b>Чистая прибыль за период</b>
            <b>{formatNumber(transactions?.totalAfterTariff)} ₽</b>
          </div>
        </div>

        <h3 style={{ marginTop: 30, marginBottom: 10 }}>История транзакций</h3>

        <div className={css.table}>
            <div className={css.tableHeader}>
              <a>Дата</a>
              <a>Назване</a>
              <a>Сумма</a>
              <a>Чистыми</a>
              <a>Тариф</a>
            </div>

            <div className={css.tableBody}>
            {transactions?.transactions?.map((item: any) => (
              <div className={css.tableDiv} onClick={() => navigate(`/admin/user/${item.userId}`)}>
                <a>{formatDate(item.createdAt)}</a>
                <a>{item.event}</a>
                <a>{formatNumber(item.value)} ₽</a>
                <a>{formatNumber(calculateNetIncome(item.value, item.tariff))} ₽</a>
                {item.tariff == 0 && <a>Стандарт</a>}
                {item.tariff == 1 && <a>Премиум</a>}
                {item.tariff == 2 && <a>Бизнес</a>}
              </div>
            ))}
            </div>
        </div>
    </MainContent>
  )
}
import { FC } from "react";
import css from './index.module.css';
import * as Types from '../../types';
import { useNavigate } from "react-router-dom";

interface Container {
  uuid: string;
  name: string;
  domain: string;
  status: number;
  isAdmin?: boolean;
}

const ContainerMini:FC<Container> = ({ uuid, name, domain, status, isAdmin = false }) => {
  const navigate = useNavigate();

  return (
    <div className={css.container} onClick={() => navigate(isAdmin === false ? `/container/${uuid}` : `/admin/container/${uuid}`)}>
        <div className={css.mainInfo}>
          <b>{name}</b>
          <a>{domain}</a>
        </div>
        <div className={css.status}>
            <a>{status === 5 ? 'Активен' : 'Настраивается'}</a>
        </div>
    </div>
  );
};

export default ContainerMini;
import '@locales/index';
import useUserData from '@hooks/useUserData';
import { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom"
import { Toaster } from 'sonner';
import NotFound from '@pages/system/notFound';
import Authorization from '@pages/auth';
import Container from '@pages/container';

import RootPage from '@pages/home';
import CreateContainer from '@pages/container/create';
import Wallet from '@pages/wallet';
import Account from '@pages/account';
import Support from '@pages/support';
import Ticket from '@pages/support/ticket';
import { isMobile } from 'react-device-detect';
import Mobile from '@pages/system/mobile';
import AdmRootPage from '@pages/admin/main';
import AdmExpenses from '@pages/admin/expenses';
import AdmSettlements from '@pages/admin/settlements';
import AdmUsers from '@pages/admin/users';
import AdmUser from '@pages/admin/users/user';
import AdmContainers from '@pages/admin/containers';
import AdmContainer from '@pages/admin/containers/container';
import AdmTicketView from '@pages/admin/support/ticket';
import AdmTickets from '@pages/admin/support';
import AdmTransactions from '@pages/admin/transactions';

function App() {
  const { userData } = useUserData();

  return (
    <Router basename="/">
      <Toaster position="bottom-right" richColors expand={false} />
      <ScrollToTop />
      <Routes>
        {!isMobile &&
        <>
          <Route path='*' element={<NotFound />} />
          <Route path='/' element={userData ? <RootPage /> : <Authorization />} />
          <Route path='/auth' element={<Authorization />} />
          <Route path='/container/create' element={userData ? <CreateContainer /> : <Authorization />} />
          <Route path='/container/:id' element={userData ? <Container /> : <Authorization />} />
          <Route path='/wallet' element={userData ? <Wallet /> : <Authorization />} />
          <Route path='/account' element={userData ? <Account /> : <Authorization />} />
          <Route path='/support' element={userData ? <Support /> : <Authorization />} />
          <Route path='/support/:id' element={userData ? <Ticket /> : <Authorization />} />
        </>
        }

        {isMobile &&
        <>
          <Route path='*' element={<Mobile />} />
          <Route path='/' element={<Mobile />} />
        </>
        }

        {userData && userData.access > 0 &&
          <>
            <Route path='/admin' element={<AdmRootPage />} />
            <Route path='/admin/expenses' element={<AdmExpenses />} />
            <Route path='/admin/settlements' element={<AdmSettlements />} />
            <Route path='/admin/users' element={<AdmUsers />} />
            <Route path='/admin/user/:id' element={<AdmUser />} />
            <Route path='/admin/containers' element={<AdmContainers />} />
            <Route path='/admin/container/:id' element={<AdmContainer />} />

            <Route path='/admin/tickets' element={<AdmTickets />} />
            <Route path='/admin/ticket/:id' element={<AdmTicketView />} />
            <Route path='/admin/transactions' element={<AdmTransactions />} />
          </>
        }
      </Routes>
    </Router>
  )
}

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

export default App

import { Link } from "react-router-dom";
import css from './index.module.css';
import SEO from "@components/seo";

export default function NotFound () {
  return (
    <div className={css.container}>
        <SEO 
            title="Страница не найдена"
            description="ArtCons"
            ogUrl="https://artcons.io/"
        />

        <div className={css.component}>        
            <div>
                <h1>потерялись?</h1>
                <a>такой страницы не существует, давайте вернемся на главную</a>

                <div className={css.back}><Link to="/">вернуться на главную</Link></div>
            </div>
        </div>

    </div>
  )
}

import { useState, useEffect, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from 'axios';
import css from './index.module.css';

import SEO from "@components/seo";
import MainContent from "@components/content";
import ContainerMini from "@components/containerMini";
import * as Types from '../../types';
import useUserData from "@hooks/useUserData";
import { toast } from "sonner";

export default function RootPage() {
  const [containers, setContainers] = useState<Types.Container[]>([]);
  const { userData } = useUserData();
  const navigate = useNavigate();
      
  useEffect(() => {
    axios.get(`${import.meta.env.VITE_API_URL}/gtm`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('authToken')
      }
      })
      .then(response  => {
        setContainers(response.data);
      })
      .catch(error => {
        setContainers([]);
    });
  }, [userData]);

  const leecyberTransfer = () => {
    if (localStorage.getItem('leecyberTransfer')) {
      toast.info('Вы уже отправили заявку на перенос');
      return;
    }

    axios.get(`${import.meta.env.VITE_API_URL}/user/leecyber`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('authToken')
      }
      })
      .then(response  => {
        toast.success('Заявка на перенос создана, спасибо');
        localStorage.setItem('leecyberTransfer', 'true');
      })
      .catch(error => {
        toast.error('Ошибка, обратитесь в поддержку.');
      });
  }
  
  return (
    <MainContent>    
      <SEO 
        title="Главная"
        description=""
        ogUrl="https://artcons.io/"
      />

      <div className={css.head}>
        <h1>Ваши контейнеры</h1>
        <Link to={'/container/create'}>Создать новый</Link>
      </div>

      {(userData?.leecyber && containers.length <= 0) && 
        <div className={css.leecyber} onClick={leecyberTransfer}>
          <h3>Перенос из LeeCyber {localStorage.getItem('leecyberTransfer') ? <i style={{ float: 'right' }} className="fi fi-rr-checkbox"></i> : <i style={{ float: 'right' }} className="fi fi-rr-square-up-right"></i> }</h3>
          <a>Бережно перенесём контейнеры из LeeCyber, ничего менять не придется.</a>
        </div>
      }

      <div className={css.containers}>
        {containers?.map(item => (
          <ContainerMini 
            uuid={item.id.toString()}
            name={item.name}
            domain={item.domains.find(i => i.type === 0)?.value!}
            status={item.status}
          />
        ))}

        {containers.length <= 0 && <a>Контейнеров нет.</a>}
      </div>
    </MainContent>
  )
}
import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from 'axios';
import css from './index.module.css';
import { isMobile } from "react-device-detect";

import SEO from "@components/seo";
import MainContent from "@components/content";

import { useTranslation } from 'react-i18next';
import * as Chart from './charts';
import * as Popups from './popups';

import * as Types from '../../types';
import { toast } from "sonner";
import Tabs from "@components/tabs";

export default function Container() {
    const { t } = useTranslation();
    let { id } = useParams();
    const [step, setStep] = useState(4);
    const [container, setContainer] = useState<Types.Container>();
    const [statistics, setStatistics] = useState<any>([]);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(0);
    const [ipStatus, setIpStatus] = useState(false);
    const [ipStatusPre, setIpStatusPre] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showModalUpd, setShowModalUpd] = useState(false);
    const [showModalCode, setShowModalCode] = useState(false);
    const [update, setUpdate] = useState(false);

    useEffect(() => {
        if (id != 'create') {
        axios.get(`${import.meta.env.VITE_API_URL}/gtm/${id}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            }}).then(response => {
                setContainer(response.data);
                setStep(response.data.status);
                if (response.data.status != 4) checkIp(response.data);

                axios.get(`${import.meta.env.VITE_API_URL}/gtm/${id}/statistics`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('authToken')
                    }}).then(responsee => {
                        setStatistics(responsee.data);
                        if (response.data[0].status == 4) setLoading(false);
                    });
            });
        }
    }, [update]);

    function checkIp(notify = false) {
        const data = {
            domain: container?.domains.find(i => i.type === 0)?.value, 
            domainPre: container?.domains.find(i => i.type === 1)?.value
        }
        
        axios.post(`${import.meta.env.VITE_API_URL}/gtm/checkDns/${container?.id}`, data, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }}).then(response => {     
            setIpStatus(response.data.domain);
            setIpStatusPre(response.data.domainPre);

            if (notify) toast.success(t('gtm.updated'));
            setLoading(false);
        }).catch(error => {
            if (notify) toast.success(t('gtm.updated'));
            setLoading(false);
            setIpStatus(false);
        });
    }   

    function enterNext() {
        axios.post(`${import.meta.env.VITE_API_URL}/gtm/create/init/${id}`, [], {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            }
        }).then(response => {       
            setStep(3);
            toast.success(t('gtm.updated'));
        }).catch(error => {
            toast.error(t('gtm.on'));
        });
    }
    
    function checkInit() {
        axios.post(`${import.meta.env.VITE_API_URL}/gtm/checkSetup/${id}`, [], {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            }
        }).then(response => {       
            if (response.data.status === "OK") {
                setStep(4);
                toast.success(t('gtm.updated'));
            } else {
                toast.info(t('gtm.loading'));
            }
        }).catch(error => {
            toast.error(t('gtm.on'));
        });
    }


  return (
    <MainContent>    
      <SEO 
        title="Контейнер"
        description=""
        ogUrl="https://artcons.io/"
      />

      <Popups.CodeInfo showModal={showModalCode} setShowModal={setShowModalCode} />
      <Popups.DeleteContainer showModal={showModal} setShowModal={setShowModal} />
      <Popups.UpdateInformation showModal={showModalUpd} setShowModal={setShowModalUpd} />

      <div className={css.head}>
        <Link to={`/`} className={css.back}><i className="fi fi-rr-arrow-small-left"></i> Вернуться</Link>
        <h1>Контейнер</h1>
        <a>{container?.status === 5 ? 'В сети' : 'Включение...'} · {container?.id}</a>
      </div>

      {step < 4 &&
            <div className={css.steps}>
                <div className={css.stepSuccess}>
                    <a>{t('gtm.step')} 1</a>
                </div>
                <div className={step == 2 ? css.stepActive : step > 2 ? css.stepSuccess : css.step}>
                    <a>{t('gtm.step')} 2</a>
                </div>
                <div className={step == 3 ? css.stepActive : step > 3 ? css.stepSuccess : css.step}>
                    <a>{t('gtm.step')} 3</a>
                </div>
            </div>
        }

        {step == 0 &&
        <>
            <div style={{ display: 'flex', gap: 10, alignItems: 'center' }}>
                <i className="fi fi-rr-loading"></i>
                <a style={{ fontSize: 18 }}>Устанавливаем сервер, пожалуйста, подождите...</a>
            </div>
            
            <button onClick={() => setUpdate(true)} type="submit" style={{ width: "100%", background: "#677add", marginTop: 20, padding: 8, color: "#fff" }}>{t('gtm.check')}</button>
        </>
        }

        {step === 2 &&
            <>
            <div style={{ display: "grid", gap: 5 }}>
                <h3>{t('gtm.dns')}</h3>
                <a style={{ fontWeight: 400 }}>{t('gtm.dnsp')}</a>
            </div>
            
            <table style={{ borderCollapse: 'collapse', position: 'relative', width: "-webkit-fill-available", marginTop: 20 }}>
                <thead>
                    <tr>
                        <th style={{ cursor: 'pointer', padding: '10px', borderBottom: '1px solid #ddd', textAlign: 'left', paddingLeft: 0 }}>TYPE</th>
                        <th style={{ cursor: 'pointer', padding: '10px', borderBottom: '1px solid #ddd', textAlign: 'left' }}>HOST</th>
                        <th style={{ cursor: 'pointer', padding: '10px', borderBottom: '1px solid #ddd', textAlign: 'left' }}>VALUE</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>{container?.ips.find(i => i.type === 'ipv4') ? "A" : "AAAA"}</td>
                        <td style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>{container?.domains.find(i => i.type === 0)?.value}</td>
                        <td style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>{container?.ips.find(i => i.type === 'ipv4')?.ip} {ipStatus ? <i className="fi fi-rr-check-circle" style={{ float: "right", color: "#6edd67" }} /> : <i className="fi fi-rr-triangle-warning" style={{ float: "right", color: "#dd6767" }} />}</td>
                    </tr>

                    <tr>
                        <td style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>{container?.ips.find(i => i.type === 'ipv6') ? "AAAA" : "A"}</td>
                        <td style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>{container?.domains.find(i => i.type === 0)?.value}</td>
                        <td style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>{container?.ips.find(i => i.type === 'ipv6')?.ip} {ipStatus ? <i className="fi fi-rr-check-circle" style={{ float: "right", color: "#6edd67" }} /> : <i className="fi fi-rr-triangle-warning" style={{ float: "right", color: "#dd6767" }} />}</td>
                    </tr>

                    <tr>
                        <td style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>{container?.ips.find(i => i.type === 'ipv4') ? "A" : "AAAA"}</td>
                        <td style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>{container?.domains.find(i => i.type === 1)?.value}</td>
                        <td style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>{container?.ips.find(i => i.type === 'ipv4')?.ip} {ipStatusPre ? <i className="fi fi-rr-check-circle" style={{ float: "right", color: "#6edd67" }} /> : <i className="fi fi-rr-triangle-warning" style={{ float: "right", color: "#dd6767" }} />}</td>
                    </tr>

                    <tr>
                        <td style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>{container?.ips.find(i => i.type === 'ipv6') ? "AAAA" : "A"}</td>
                        <td style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>{container?.domains.find(i => i.type === 1)?.value}</td>
                        <td style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>{container?.ips.find(i => i.type === 'ipv6')?.ip} {ipStatusPre ? <i className="fi fi-rr-check-circle" style={{ float: "right", color: "#6edd67" }} /> : <i className="fi fi-rr-triangle-warning" style={{ float: "right", color: "#dd6767" }} />}</td>
                    </tr>
                </tbody>
            </table>

            {(!ipStatus || !ipStatusPre) ? <button onClick={() => checkIp(true)} type="submit" style={{ width: "100%", background: "#0f51d4", marginTop: 10, padding: 10 }}>{t('gtm.check')}</button> : <button onClick={enterNext} type="submit" style={{ width: "100%", background: "#0f51d4", marginTop: 10, padding: 10, color: "#fff" }}>{t('gtm.next')}</button>}
            </>
        }

        {step == 3 &&
        <>
            <div style={{ display: 'flex', gap: 10, alignItems: 'center' }}>
                <i className="fi fi-rr-loading"></i>
                <a style={{ fontSize: 18 }}>{t('gtm.loading')}</a>
            </div>
            <button onClick={checkInit} type="submit" style={{ width: "100%", background: "#677add", marginTop: 20, padding: 8, color: "#fff" }}>{t('gtm.check')}</button>
        </>
        }

        {step >= 4 &&
        <>
            <div className={css.tabs}>
                <Tabs 
                    sections={[
                        {
                            id: 0,
                            name: 'Общая информация'
                        },
                        {
                            id: 1,
                            name: 'Надстройки',
                            disabled: true
                        }
                    ]}

                    active={page}
                    setActive={setPage}
                />
            </div>
            
            <div style={{ display: 'flex', gap: 20 }}>
                <div style={{ display: 'grid', gap: 10, marginTop: 20, flex: 1 }}>  
                    {statistics.cpu && statistics.cpu.statistic.length > 0 ?
                    <div style={{ boxSizing: 'border-box', border: "1px solid #00000042", padding: "25px 0px 65px 25px", borderRadius: 20, flex: 1}}>
                        <Chart.CpuChart data={[...statistics.cpu.statistic].reverse()} />
                    </div>
                    :
                    <div style={{ boxSizing: 'border-box', border: "1px solid #00000042", padding: "0px 25px 25px 25px", borderRadius: 20, flex: 1}}>
                        <h5 style={{ marginBottom: 15, fontSize: 17 }}>{t('gtm.cpu')}</h5>
                        <a>Данные появятся в течение дня</a>
                    </div>
                    }

                    {statistics.disk && statistics.disk.statistic.length > 0 ?
                        <div style={{ boxSizing: 'border-box', border: "1px solid #00000042", padding: "0px 0px 20px 25px", borderRadius: 20, flex: 1}}>
                            <h5 style={{ fontSize: 17 }}>{t('gtm.disk')}</h5>
                            <Chart.DiskChart data={[...statistics.disk.statistic].reverse()} />
                        </div>
                        :
                        <div style={{ boxSizing: 'border-box', border: "1px solid #00000042", padding: "25px 0px 25px 25px", borderRadius: 20, flex: 1}}>
                            <h5 style={{ marginBottom: 15, fontSize: 17 }}>{t('gtm.disk')}</h5>
                            <a>Данные появятся в течение дня</a>
                        </div>
                    }

                    {statistics.ram && statistics.ram.statistic.length > 0 ?
                        <div style={{ boxSizing: 'border-box', border: "1px solid #00000042", padding: "0px 0px 20px 25px", borderRadius: 20, flex: 1, height: 310}}>
                            <h5 style={{ fontSize: 17 }}>{t('gtm.ram')}</h5> 
                            <Chart.RamChart data={[...statistics.ram.statistic].reverse()} />
                        </div>
                        :
                        <div style={{ boxSizing: 'border-box', border: "1px solid #00000042", padding: "25px 0px 25px 25px", borderRadius: 20, flex: 1}}>
                            <h5 style={{ marginBottom: 15, fontSize: 17 }}>{t('gtm.ram')}</h5>
                            <a>Данные появятся в течение дня</a>
                        </div>
                    } 
                </div>

                <div style={{ flex: 0.3 }}>
                    <div style={{ width: '100%', border: "1px solid #00000042", padding: 25, boxSizing: 'border-box', marginTop: 20, borderRadius: 20, height: 'fit-content' }}>
                        <a style={{ fontWeight: 600, fontSize: 17 }}>{t('gtm.information')}</a>

                        <div className={css.infoBlock}>
                            <a>{container?.ips.find(i => i.type === 'ipv4') ? "IPv4" : "IPv6"}</a>
                            <b>{container?.ips.find(i => i.type === 'ipv4')?.ip}</b>
                        </div>

                        <div className={css.infoBlock}>
                            <a>{container?.ips.find(i => i.type === 'ipv6') ? "IPv6" : "IPv4"}</a>
                            <b>{container?.ips.find(i => i.type === 'ipv6')?.ip}</b>
                        </div>
                        
                        <div className={css.infoBlock}>
                            <a>{t('gtm.domainName')}</a>
                            <b>{container?.domains.find(i => i.type === 0)?.value}</b>
                        </div>

                        {/* <div className={css.btnDelete} onClick={() => setShowModalUpd(true)} style={{ background: "#6258ee", color: "#fff" }}>{t('gtm.update')}</div> */}
                        <div className={css.btnDelete} onClick={() => setShowModalCode(true)} style={{ background: "#41a341", marginTop: 20, color: "#fff" }}>{t('gtm.installSite')}</div>
                        <div className={css.btnDelete} onClick={() => setShowModal(true)} style={{ marginTop: 5, color: "#fff" }}>{t('gtm.deteleBtn')}</div>
                    </div>

                    <div style={{ width: '100%', border: "1px solid #00000042", padding: 25, boxSizing: 'border-box', marginTop: 20, borderRadius: 20, height: 'fit-content' }}>
                        <a style={{ fontWeight: 600, fontSize: 17 }}>{t('gtm.warn')}</a>
                        <p style={{ margin: 0, marginTop: 10, fontSize: 15 }}>{t('gtm.warnp')}</p>
                    </div>
                </div>
            </div>
        </>
        }
    </MainContent>
  )
}
import { useState, useEffect, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from 'axios';
import css from './index.module.css';

import SEO from "@components/seo";
import MainContent from "@components/content";
import useUserData from "@hooks/useUserData";

export default function AdmUsers() {
  const [users, setUsers] = useState<any>([]);
  const navigate = useNavigate();
      
  useEffect(() => {
    axios.get(`${import.meta.env.VITE_API_ADM_URL}/users`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      })
      .then(response  => {
        setUsers(response.data);
      })
      .catch(error => {
        setUsers([]);
      });
  }, []);

  return (
    <MainContent>    
      <SEO 
        title="Пользователи"
        description=""
        ogUrl="https://artcons.io/"
      />

      <div className={css.head}>
        <h1>Пользователи</h1>
      </div>

      <div className={css.table}>
          <div className={css.tableHeader}>
            <a>Номер телефона</a>
            <a>Электронная почта</a>
            <a>Имя</a>
            <a>Фамилия</a>
            <a>Промокод</a>
          </div>

          <div className={css.tableBody}>
          {users.map((item: any) => (
            <div className={css.tableDiv} onClick={() => navigate(`/admin/user/${item.id}`)}>
              <a>{item.phone}</a>
              <a>{item.email}</a>
              <a>{item.firstName}</a>
              <a>{item.lastName}</a>
              <a>{item.promocode}</a>
            </div>
          ))}
          </div>
      </div>
    </MainContent>
  )
}
import { useState, useEffect, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from 'axios';
import css from './index.module.css';

import SEO from "@components/seo";
import MainContent from "@components/content";
import ContainerMini from "@components/containerMini";
import * as Types from '../../../types';
import useUserData from "@hooks/useUserData";
import * as Charts from './charts';
import { Faces, Status } from "../settlements/types";

export default function AdmRootPage() {
  const [tab, setTab] = useState(1);
  const [period, setPeriod] = useState('0');

  const [payStats, setPayStats] = useState<any>({});
  const [recStats, setRecStats] = useState<any>({});
  const [expStats, setExpStats] = useState<any>({});

  const { userData } = useUserData();
  const navigate = useNavigate();
      
  useEffect(() => {
    axios.get(`${import.meta.env.VITE_API_ADM_URL}/pay/stats`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      })
      .then(response  => {
        setRecStats(response.data);
      })
      .catch(error => {
          setPayStats([]);
      });
  }, [userData]);

  useEffect(() => {
    axios.get(`${import.meta.env.VITE_API_ADM_URL}/pay/stats/payments?days=${period}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('authToken')
      }
    })
    .then(response  => {
        setPayStats(response.data);
    })
    .catch(error => {
        setPayStats([]);
    });

    axios.get(`${import.meta.env.VITE_API_ADM_URL}/pay/stats/expenses?days=${period}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      })
      .then(response  => {
          setExpStats(response.data);
      })
      .catch(error => {
          setExpStats([]);
      });
  }, [period, userData]);

  const formatNumber = (num: any) => {
    if (num == null) return '0';
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  };

  return (
    <MainContent>    
        <SEO 
            title="Главная"
            description=""
            ogUrl="https://artcons.io/"
        />

        <div className={css.head}>
            <h1>Обзор ключевых показателей</h1>
        </div>

        <div className={css.charts}>
            <div className={css.chartsHead}>
                <div className={css.chartsHeadBtns}>
                    <div className={`${css.chartsHeadBtn} ${tab === 0 && css.active}`} onClick={() => setTab(0)}>
                        <a>Выручка</a>
                        <b>{formatNumber(payStats?.summary?.totalAmount) || 0} ₽</b>
                    </div>

                    <div className={`${css.chartsHeadBtn} ${tab === 1 && css.active}`} onClick={() => setTab(1)}>
                        <a>Расходы</a>
                        <b>{formatNumber(expStats?.summary?.totalAmount) || 0} ₽</b>
                    </div>

                    <div className={`${css.chartsHeadBtn} ${tab === 2 && css.active}`} onClick={() => setTab(2)}>
                        <a>Платежи</a>
                        <b>{formatNumber(payStats?.summary?.totalCount) || 0}</b>
                    </div>

                    <div className={`${css.chartsHeadBtn}`}>
                        <a>Активные контейнеры (₽/мес)</a>
                        <b>{formatNumber(recStats?.total) || 0} ₽</b>
                    </div>
                </div>
                <select className={css.chartsDate} onChange={(e) => setPeriod(e.target.value)}>
                    <option selected={period === '7'} value={7}>Последние 7 дней</option>
                    <option selected={period === '30'} value={30}>Последние 30 дней</option>
                    <option selected={period === '90'} value={90}>Последние 90 дней</option>
                    <option selected={period === '365'} value={365}>Последние 365 дней</option>
                </select>
            </div>
            
            <div className={css.chart}>
                {tab === 0 && <Charts.Payments data={payStats} /> }
                {tab === 1 && <Charts.Payments data={expStats} /> }
                {tab === 2 && <Charts.PaymentsCount data={payStats} /> }
            </div>
        </div>    

        <div className={css.blocks}>
            <Link to={`/admin/users`} className={css.block}>
                <a>Всего пользователей</a>
                <b>{recStats?.userCount}</b>
            </Link>

            <Link to={`/admin/containers`} className={css.block}>
                <a>Всего контейнеров</a>
                <b>{recStats?.containerCount}</b>
            </Link>

            <Link to={`/admin/tickets`} className={css.block}>
                <a>Всего обращений (вкл. закрытые)</a>
                <b>{recStats?.ticketCount}</b>
            </Link>
        </div>
    </MainContent>
  )
}